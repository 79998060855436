body {
  height: 100vh;
  width: 100vw;
  margin: 0;
  font-size: 20px;
  background: black;
  color: white; }

* {
  box-sizing: border-box; }

button {
  font-size: inherit; }

.selectable, .pre-sel .cont .opt, .pre-sel .bottom {
  background: purple;
  text-align: center;
  cursor: pointer;
  padding: 1em;
  margin: 0.5em;
  user-select: none;
  transition: all 0.25s; }
  .selectable.unselected, .pre-sel .cont .unselected.opt, .pre-sel .unselected.bottom {
    filter: brightness(50%);
    transform: scale(0.95); }

.pre-sel {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 0.5em;
  text-align: center; }
  .pre-sel .prompt {
    display: block; }
  .pre-sel .cont {
    flex: 1 1 auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap; }
    .pre-sel .cont .opt {
      flex: 1 0 auto; }
  .pre-sel .bottom {
    flex: 0 0 auto;
    display: block; }
